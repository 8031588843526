import { createFeatureSelector, createSelector } from "@ngrx/store";
import { UserState } from "./user.state";

export const userFeatureKey = 'user';
export const selectUserFeature = createFeatureSelector<UserState>(userFeatureKey);
export const selectAllUsers = createSelector(
  selectUserFeature,
  (userState: UserState) => userState.users
);
export const selectLoadingUsers = createSelector(
  selectUserFeature,
  (userState: UserState) => userState.loadingUsers
);
export const selectUser = createSelector(selectUserFeature, (userState: UserState) => userState.user);
export const selectLoadingUser = createSelector(
  selectUserFeature,
  (userState: UserState) => userState.loadingUser
);
export const selectUserUpdated = createSelector(
  selectUserFeature,
  (userState: UserState) => userState.userUpdated
);
export const selectUserDeleted = createSelector(
  selectUserFeature,
  (userState: UserState) => userState.userDeleted
);
export const selectUserError = createSelector(selectUserFeature, (userState: UserState) => userState.error);
