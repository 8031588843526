import { User } from "./user.model";

export interface UserState {
    users: User[];
    user: User;
    loadingUsers: boolean;
    loadingUser: boolean;
    userUpdated: boolean;
    userDeleted: boolean;
    error: any;
}

export const emptyUser: User = {
    name: '',
    email: '',
    role: ''
}

export const initialUserState: UserState = {
    users: [],
    user: emptyUser,
    loadingUsers: false,
    loadingUser: false,
    userUpdated: false,
    userDeleted: false,
    error: ''
}