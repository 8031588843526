import { createAction, props } from "@ngrx/store";
import { User } from "./user.model";

export enum UserActionType {
    GET_ALL_USERS = '[User] Get All Users',
    GET_ALL_USERS_SUCCESS = '[User] Get All Users :: Success',
    GET_USER = '[User] Get User',
    GET_USER_SUCCESS = '[User] Get User :: Success',
    CLEAR_USER = '[User] Clear User',
    CREATE_USER = '[User] Create User',
    CREATE_USER_SUCCESS = '[User] Create User :: Success',
    CREATE_USER_ERROR = '[User] Create User :: Error',
    RESET_USER_ERROR = '[User] Reset User Error',
    UPDATE_USER = '[User] Update User',
    UPDATE_USER_SUCCESS = '[User] Update User :: Success',
    UPDATE_USER_PASSWORD = '[User] Update User Password',
    UPDATE_USER_PASSWORD_SUCCESS = '[User] Update User Password :: Success',
    RESET_USER_UPDATED = '[User] Reset User Updated',
    DELETE_USER = '[User] Delete User',
    DELETE_USER_SUCCESS = '[User] Delete User :: Success',
    RESET_USER_DELETED = '[User] Reset User Deleted',
}

export const getAllUsers = createAction(UserActionType.GET_ALL_USERS);
export const getAllUsersSuccess = createAction(
    UserActionType.GET_ALL_USERS_SUCCESS,
  props<{ users: User[] }>()
);
export const getUser = createAction(UserActionType.GET_USER, props<{ id: number }>());
export const getUserSuccess = createAction(
    UserActionType.GET_USER_SUCCESS,
  props<{ user: User }>()
);
export const clearUser = createAction(UserActionType.CLEAR_USER);
export const createUser = createAction(
    UserActionType.CREATE_USER,
  props<{ user: User }>()
);
export const createUserSuccess = createAction(
    UserActionType.CREATE_USER_SUCCESS,
  props<{ user: User }>()
);
export const createUserError = createAction(UserActionType.CREATE_USER_ERROR, props<{ error: any }>());
export const resetUserError = createAction(UserActionType.RESET_USER_ERROR);
export const updateUser = createAction(
    UserActionType.UPDATE_USER,
  props<{ user: User }>()
);
export const updateUserSuccess = createAction(
    UserActionType.UPDATE_USER_SUCCESS,
  props<{ user: User }>()
);
export const updateUserPassword = createAction(UserActionType.UPDATE_USER_PASSWORD, props<{ user: User }>());
export const updateUserPasswordSuccess = createAction(UserActionType.UPDATE_USER_PASSWORD_SUCCESS);
export const resetUserUpdated = createAction(UserActionType.RESET_USER_UPDATED);
export const deleteUser = createAction(
    UserActionType.DELETE_USER,
  props<{ id: number }>()
);
export const deleteUserSuccess = createAction(UserActionType.DELETE_USER_SUCCESS);
export const resetUserDeleted = createAction(UserActionType.RESET_USER_DELETED);
